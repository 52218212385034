<template>

  <v-container
    fluid
    tag="section"
  >

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>




<base-material-card
      icon="mdi-arrow-top-right-bold-box"
      title="Indicação"
      class="px-5 py-3">
<v-form ref="form">
    <v-card-text>
      <v-container>
        <v-row>
      
            <v-col cols="6">
             <v-text-field 
              label="Nome do Cliente *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="indicacao.nomeCliente" x-large
              prepend-inner-icon="mdi-account-box"
            ></v-text-field>

            </v-col>

            <v-col cols="6">
              <v-text-field 
              label="Celular do Cliente *"  v-mask="'(##) #####-####'"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="indicacao.telefoneCliente"
              prepend-inner-icon="mdi-cellphone-basic"
            ></v-text-field>            
            </v-col>

            <v-col cols="12" sm="6">
              <v-select  v-model="indicacao.evento"  item-value="id" item-text="evento" :items="indicacoes"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled  label="Tipo de Indicação  *" prepend-inner-icon="mdi-bookmark"></v-select>
             </v-col>

            <v-col cols="12" md="6">
              <v-text-field 
                label="Contrato/Inscrição do Cliente"  style="margin-left:4px;"
                filled v-model="indicacao.contrato"
                prepend-inner-icon="mdi-bookmark"
              ></v-text-field> 
            </v-col>

             <v-col cols="12" md="6">
              <v-text-field 
                label="Observação"  style="margin-left:4px;"
                filled v-model="indicacao.observacao"
                prepend-inner-icon="mdi-bookmark"
              ></v-text-field> 
            </v-col>


        </v-row>
                          * campos obrigatórios

      </v-container> 

      <v-spacer> </v-spacer>
      <br/>
                      <v-btn color="success" :loading="this.btn_load"  @click="indicar()" >Salvar
                      <v-icon right dark>mdi-content-save</v-icon>
      </v-btn>
    </v-card-text>
  </v-form>  
</base-material-card>
  </v-container>    
</template>
<script>

  import Promotor from '../../services/promotor'

  export default {
    name: 'Promotor',
   
    mounted () {
      this.listar_indicacao();
    },

    data () {
      return {
        components: {
            DashboardCoreView: () => import('../dashboard/components/core/View'),
        },
        dialog:true,
        alert_msg:false,
        msg_dialog:'',
        btn_load: false,
        indicacoes:[],
       
        indicacao:{
            nomeCliente: '',
            telefoneCliente:'',
            contrato: '',
            observacao: '',
            evento: 0,
        },
        loading:false,
      }
    },

   
    methods: {

        clear () {
          this.$refs.form.reset();
        },
  

      ok_val(){
        this.$router.push('login');
      },
     

      indicar(){
        this.btn_load = true;
        if(this.$refs.form.validate()){
            Promotor.indicacao_flores(this.indicacao).then(response => {
              
              if(response.data.codigo){
                  this.alert_msg = true;
                  this.msg_dialog = response.data.mensagem;
                  this.clear();

              }

              }).catch(e => {
              if(e.status === 401){
                 this.loading = false
                   this.alert_msg = true;
                   this.btn_load = false;
                  this.msg_dialog = "Por Favor tente novamente mais tarde!";
              }
             }).finally(() => {
                this.loading = false
                 this.btn_load = false;
              })
              
        
        }else{
          this.alert_msg = true;
          this.msg_dialog = "Preencha todos os campos";
          this.btn_load = false;

        }

      },

      listar_indicacao () {
       this.loading = true
        Promotor.eventos_indicacao().then(response => {
            this.indicacoes = response.data;

        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
            }

        }).finally(() => {
            this.loading = false

        })
      },


    },
  }
</script>
